import React from "react";
import { MdOndemandVideo } from "react-icons/md";
import { useMediaQuery } from 'react-responsive';
import { PiPhoneCallFill } from "react-icons/pi";
import { IoIosCheckboxOutline } from "react-icons/io";
import styles from "./FixIcon.module.scss";

import icon from "../../assets/FixIcon/open.png";

const FixIcon = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

    return (
        <>{!isMobile ? (
            <div className={styles.container}>
                <a className={styles.fixedVideo} href="https://youtu.be/-Zk2cRasQBY?si=bsl-OCEZOVj8dZkT">
                    <MdOndemandVideo color="#114761" size={30} />
                </a>
                <a className={styles.fixedicon} href="https://naver.me/FWfnlWCx">
                    <img src={icon} alt="jungheung-class-bener" />
                </a>
            </div>
        ) : (
            <div>
                <a className={styles.fixedicon} href="https://naver.me/FWfnlWCx">
                    <img src={icon} alt="jungheung-class-bener" />
                </a>
                <div className={styles.buttomBtnContainer}>
                    <a className={styles.btn1} href="https://naver.me/FWfnlWCx">
                        <IoIosCheckboxOutline size={20} />
                        <div>방문예약</div>
                    </a>
                    <a className={styles.btn2} href={`tel:1533-8848`}>
                        <PiPhoneCallFill size={25} />
                        <div>전화상담</div>
                    </a>
                </div>
            </div>
        )}
        </>
    )
}

export default FixIcon;