import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import styles from './Brand.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";

import page1 from "../../assets/Brand/intro/brand1.jpg";
import page2 from "../../assets/Brand/intro/brand2.jpg";
import FixIcon from "../../components/FixIcon/FixIcon";

const Brand1 = () => {
	const menuContents = [{ title: "중흥S-클래스", url: "/brand/intro" }, { title: "브랜드 영상", url: "/brand/video" }]
	const [isScroll, setIsScroll] = useState(false);

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>
			<Header isChanged={isScroll}/>
			<FixIcon />

			<Bener title="브랜드" />

			<MenuBar contents={menuContents} />

			<img className={styles.image} src={page1} alt="jungheung-class-brand-page1" />
			<img className={styles.image} src={page2} alt="jungheung-class-brand-page2" />

			<Footer />
		</div>
	)
}

export default Brand1;