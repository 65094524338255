import React from "react";
import { useMediaQuery } from 'react-responsive';
import styles from "./Footer.module.scss"
import footerlogo from "../../assets/logo/footerlogo.png";

const Footer = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

	return (
		<>{!isMobile ? (

			<div className={styles.container}>

				<img className={styles.img} src={footerlogo} alt="jungheung-class-footer-logo" />

				<div className={styles.textContainer}>
					<div className={styles.text}>COPYRIGHTⓒ평택 브레인시티 중흥S-클래스 모델하우스. ALL RIGHTS RESERVED.</div>
					<div className={styles.text}>※본 아파트의 하자 등에 따른 소비자 피해보상은 공동주택관리법 제36조 제37조에 따라 적용됩니다.</div>
					<div className={styles.text}>※본 사이트에 사용된 이미지 및 내용, 문구 등은 소비자의 이해를 돕기 위해 제작 또는 표기된 것으로 실제와 차이가 있습니다.</div>
					<div className={styles.text}>※본 사이트 상의 개발 및 교통계획에 대한 사항은 추후 관계기관의 사정에 따라 변경 및 취소될 수 있으며, 이는 당사와 무관함을 알려드립니다.</div>
					<div className={styles.text}>※본 사이트의 내용은 사업주체 및 관계기관의 사정에 따라 변경될 수 있습니다.</div>
					<div className={styles.text}>※본 사이트는 편집 및 인쇄과정에서 오류 및 오타가 있을 수 있습니다.</div>
					<div className={styles.text}>※상기 내용 및 이미지는 소비자의 이해를 돕기 위한 것으로 실제와 다를 수 있습니다.</div>
				</div>
			</div>

		) : (

			<div className={styles.container}>

				<img className={styles.img} src={footerlogo} alt="jungheung-class-footer-logo" />

				<div className={styles.textContainer}>
					<div className={styles.text}>COPYRIGHTⓒ평택 브레인시티 중흥S-클래스 모델하우스. ALL RIGHTS RESERVED.</div>
					<div className={styles.text}>※본 아파트의 하자 등에 따른 소비자 피해보상은 공동주택관리법 제36조 제37조에 따라 적용됩니다.</div>
					<div className={styles.text}>※본 사이트에 사용된 이미지 및 내용, 문구 등은 소비자의 이해를 돕기 위해 제작 또는 표기된 것으로 실제와 차이가 있습니다.</div>
					<div className={styles.text}>※본 사이트 상의 개발 및 교통계획에 대한 사항은 추후 관계기관의 사정에 따라 변경 및 취소될 수 있으며, 이는 당사와 무관함을 알려드립니다.</div>
					<div className={styles.text}>※본 사이트의 내용은 사업주체 및 관계기관의 사정에 따라 변경될 수 있습니다.</div>
					<div className={styles.text}>※본 사이트는 편집 및 인쇄과정에서 오류 및 오타가 있을 수 있습니다.</div>
					<div className={styles.text}>※상기 내용 및 이미지는 소비자의 이해를 돕기 위한 것으로 실제와 다를 수 있습니다.</div>
				</div>
			</div>

		)}
		</>
	)
}

export default Footer