import React from "react";
import styles from "./Bener.module.scss";

import img from "../../assets/Bener/bener.jpg";

const Bener = ({title}) => {
    return(
        <>
            <img className={styles.bener} src={img} alt="jungheung-class-bener" />
            <div className={styles.title}>{title}</div>
        </>
    )
}

export default Bener;