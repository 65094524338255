import React, { useEffect, useState } from "react";
import styles from './FloorPlan.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";

import page1 from "../../assets/FloorPlan/FloorPlan2/page1.jpg";
import page2 from "../../assets/FloorPlan/FloorPlan2/page2.jpg";
import page3 from "../../assets/FloorPlan/FloorPlan2/page3.jpg";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";

const FloorPlan2 = () => {
	const menuContents = [
		{ title: "59A", url: "/FloorPlan/59A" },
		{ title: "59B", url: "/FloorPlan/59B" },
		{ title: "84A", url: "/FloorPlan/84A" },
		{ title: "84B", url: "/FloorPlan/84B" },
		{ title: "84C", url: "/FloorPlan/84C" },

	];
	const [isScroll, setIsScroll] = useState(false);

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>
			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="평면안내"/>

			<MenuBar contents={menuContents} />

			<img className={styles.image} src={page1} alt="jungheung-class-LocationEnvironment-page1" />
			<img className={styles.image} src={page2} alt="jungheung-class-LocationEnvironment-page2" />
			<img className={styles.image} src={page3} alt="jungheung-class-LocationEnvironment-page3" />
			<Footer />
		</div>
	)
}

export default FloorPlan2;