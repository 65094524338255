import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useCookies } from "react-cookie";
import styles from "./Main.module.scss"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Popup from "../../components/Popup/Popup";
import FixIcon from "../../components/FixIcon/FixIcon";

import mainImage from "../../assets/Main/Main1.jpg";
import mainImage_Mobile from "../../assets/Main/Main1_Mobile.jpg";
import page1 from "../../assets/Main/Main2.jpg";
import page2 from "../../assets/Main/Main3.jpg";
import page3 from "../../assets/Main/Main4.jpg";
import page4 from "../../assets/Main/Main5.jpg";
import page5 from "../../assets/Main/Main6.jpg";
import page6 from "../../assets/Main/Main7.jpg";

const Main = () => {
	const [isScroll, setIsScroll] = useState(false);
	const [isPopOpen, setIsPopOpen] = useState(true);
	const [hasCookie, setHasCookie] = useState(false); // 쿠키가 저장되어있는가
	const [cookies, setCookie] = useCookies(['Popup_Cookie']); // 쿠키에 저장되는 내용
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

	// 페이지 열면 바로 쿠키 유무 확인
	useEffect(() => {
		if (cookies['Popup_Cookie']) setHasCookie(true);
		// Popup_Cookie라는 쿠키가 있어?
		else setHasCookie(false); // 없어?
	}, [cookies]);

	useEffect(() => {
		if (hasCookie) setIsPopOpen(false);
	}, [hasCookie])

	useEffect(() => {
		if (isPopOpen) document.body.style.overflow = 'hidden'; // 스크롤 금지
		else document.body.style.overflow = 'auto'; // 스크롤 허용


		// 컴포넌트가 언마운트될 때 스크롤을 원래 상태로 복원
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [isPopOpen])

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			{!isMobile ? (

				<div className={styles.container}>
					{isPopOpen && <Popup onClosed={() => setIsPopOpen(false)} />}
					<Header isChanged={isScroll}/>
					<FixIcon />

					<img className={styles.mainImage} src={mainImage} alt="jungheung-class-mainImage" />
					<img className={styles.image} src={page1} alt="jungheung-class-page1" />
					<img className={styles.image} src={page2} alt="jungheung-class-page2" />
					<img className={styles.image} src={page3} alt="jungheung-class-page3" />
					<img className={styles.image} src={page4} alt="jungheung-class-page4" />
					<img className={styles.image} src={page5} alt="jungheung-class-page5" />
					<img className={styles.image} src={page6} alt="jungheung-class-page6" />

					<Footer />
				</div>

			) : (

				<div className={styles.container}>
					{isPopOpen && <Popup onClosed={() => setIsPopOpen(!isPopOpen)} />}
					<Header isChanged={isScroll}/>
					<FixIcon />

					<img className={styles.mainImage} src={mainImage_Mobile} alt="jungheung-class-mainImage" />
					<img className={styles.image} src={page1} alt="jungheung-class-page1" />
					<img className={styles.image} src={page2} alt="jungheung-class-page2" />
					<img className={styles.image} src={page3} alt="jungheung-class-page3" />
					<img className={styles.image} src={page4} alt="jungheung-class-page4" />
					<img className={styles.image} src={page5} alt="jungheung-class-page5" />
					<img className={styles.image} src={page6} alt="jungheung-class-page6" />

					<Footer />
				</div>

			)}
		</>
	)
}

export default Main;