import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import YouTube from 'react-youtube';

import styles from './Brand.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";

const Brand2 = () => {
	const menuContents = [{ title: "중흥S-클래스", url: "/brand/intro" }, { title: "브랜드 영상", url: "/brand/video" }]
	const [isScroll, setIsScroll] = useState(false);
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>
			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="브랜드" />

			<MenuBar contents={menuContents} />

			<div className={styles.videoContainer}>
			<YouTube
					videoId="-Zk2cRasQBY"
					opts={{
						width: isMobile ? "350vw" : "1000",  // 모바일에서는 100%로 설정
						height: isMobile ? "300" : "500", // 모바일 높이를 고정
						playerVars: {
							autoplay: 1,        // 자동 재생
							rel: 0,             // 관련 동영상 표시하지 않음
							modestbranding: 1,  // 컨트롤 바에 YouTube 로고를 표시하지 않음
						},	
					}}
					onEnd={(e) => {
						e.target.stopVideo(0);  // 비디오 종료 시 정지
					}}
				/>

			</div>

			<Footer />
		</div>
	)
}

export default Brand2;