import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import { Link } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { PiPhoneCallFill } from "react-icons/pi";
import { AiOutlineMenu } from "react-icons/ai";

import styles from "./Header.module.scss";
import SlideMenu from "../../components/SlideMenu/SlideMenu";

import logoImageHover from "../../assets/logo/mainlogowhite.png";
import logoImage from "../../assets/logo/mainlogo.png";

const menuArray = [
	{
		title: "브랜드",
		subMenu: [
			{ subTitle: "중흥S-클래스", subUrl: "/Brand/intro" },
			{ subTitle: "브랜드 영상", subUrl: "/Brand/video" },
		],
	},
	{
		title: "사업안내",
		subMenu: [
			{ subTitle: "사업개요", subUrl: "/BusinessGuide/intro" },
			{ subTitle: "계약서류안내", subUrl: "/BusinessGuide/documents" },
		],
	},
	{
		title: "입지환경",
		subMenu: [
			{ subTitle: "프리미엄", subUrl: "/LocationEnvironment/intro" },
			{ subTitle: "커뮤니티", subUrl: "/LocationEnvironment/community" },
		],
	},
	{
		title: "단지안내",
		subMenu: [{ subTitle: "동호수배치도", subUrl: "/ComplexGuide/intro" }],
	},
	{
		title: "평면안내",
		subMenu: [
			{ subTitle: "59A", subUrl: "/FloorPlan/59A" },
			{ subTitle: "59B", subUrl: "/FloorPlan/59B" },
			{ subTitle: "84A", subUrl: "/FloorPlan/84A" },
			{ subTitle: "84B", subUrl: "/FloorPlan/84B" },
			{ subTitle: "84C", subUrl: "/FloorPlan/84C" },
		],
	},
	{
		title: "인테리어",
		subMenu: [
			{ subTitle: "59A", subUrl: "/Interior/59A" },
			{ subTitle: "84A", subUrl: "/Interior/84A" },
			{ subTitle: "84B", subUrl: "/Interior/84B" },
		],
	},
];

const Header = ({ isChanged }) => {
	const [isChange, setIsChange] = useState(isChanged);
	const [isMenu, setIsMenu] = useState(false);
	const [isMobileMenu, setIsMobileMenu] = useState(false);
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' });

	useEffect(() => {
		if (!isMobile) setIsChange(!isChanged);
	}, [isChanged, isMobile]);

	const handleMouseEnter = () => {
		setIsChange(false);
		setIsMenu(true);
	};

	const handleMouseLeave = () => {
		if (!isChanged) setIsChange(true);
		setIsMenu(false);
	};

	return (
		<>
			{!isMobile ? (
				<header
					className={isChange ? styles.containerFirst : styles.container}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<Link className={styles.item} to='/'>
						<img src={isChange ? logoImageHover : logoImage} alt="jungheung-class-mainLogo-image" />
					</Link>

					<div className={isChange ? styles.menuContainerFirst : styles.menuContainer}>
						{menuArray.map((menu, idx) => (
							<Link key={idx} className={styles.item} to={menu.subMenu[0].subUrl}>
								<div>{menu.title}</div>
							</Link>
						))}
					</div>

					<a href="https://naver.me/FWfnlWCx" className={isChange ? styles.ContactInformationWhite : styles.ContactInformationBlack}>
						1533-8848
					</a>

					{isMenu && (
						<div className={styles.secondItemContainer}>
							<div className={styles.secondItemBox}>
								{menuArray.map((menu, idx) => (
									<div key={idx} className={styles.secondItemBox}>
										<div className={styles.secondItemTitle}>{menu.title}</div>
										{menu.subMenu.map((subItem, subIdx) => (
											<Link key={subIdx} className={styles.secondItem} to={subItem.subUrl}>
												<div>{subItem.subTitle}</div>
											</Link>
										))}
									</div>
								))}
							</div>
						</div>
					)}
				</header>
			) : (
				<header className={styles.container}>
					{!isMobileMenu ? (
						<AiOutlineMenu size={30} color={"#000000"} onClick={() => setIsMobileMenu(!isMobileMenu)} />
					) : (
						<IoCloseSharp size={35} color={"#000000"} onClick={() => setIsMobileMenu(!isMobileMenu)} />
					)}
					{isMobileMenu && <SlideMenu contents={menuArray} isClicked={isMobileMenu} />}

					<Link className={styles.item} to='/'>
						<img src={logoImage} alt="jungheung-class-mainLogo-image" />
					</Link>

					<a href={`tel:1533-8848`}>
						<PiPhoneCallFill size={30} color={"#000000"} />
					</a>
				</header>
			)}
		</>
	);
};

export default Header;
